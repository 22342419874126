import { SectionWrapper } from "../hoc";
import { BallCanvas } from "./canvas/";

const Tech = () => {
  return (
    <div className="h-[100vh]">
      <BallCanvas />
    </div>
  );
};

export default SectionWrapper(Tech, "");
