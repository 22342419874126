import {
  mobile,
  backend,
  creator,
  web,
  javascript,
  typescript,
  html,
  css,
  reactjs,
  redux,
  tailwind,
  nodejs,
  git,
  carrent,
  jobit,
  threejs,
  jet,
  scs,
  spacetourism,
} from "../assets";

export const navLinks = [
  {
    id: "about",
    title: "About",
  },
  {
    id: "work",
    title: "Work",
  },
  {
    id: "contact",
    title: "Contact",
  },
];

const services = [
  {
    title: "Web Developer",
    icon: web,
  },
  {
    title: "Frontend Developer",
    icon: mobile,
  },
  {
    title: "Lifelong Learner",
    icon: backend,
  },
  {
    title: "Avid Gamer",
    icon: creator,
  },
];

const technologies = [
  {
    name: "HTML 5",
    icon: html,
  },
  {
    name: "CSS 3",
    icon: css,
  },
  {
    name: "JavaScript",
    icon: javascript,
  },
  {
    name: "TypeScript",
    icon: typescript,
  },
  {
    name: "React JS",
    icon: reactjs,
  },
  {
    name: "Redux Toolkit",
    icon: redux,
  },
  {
    name: "Tailwind CSS",
    icon: tailwind,
  },
  {
    name: "Node JS",
    icon: nodejs,
  },
  {
    name: "Three JS",
    icon: threejs,
  },
  {
    name: "git",
    icon: git,
  },
];

const experiences = [
  {
    title: "Assistant Language Teacher",
    company_name: "JET Program",
    icon: jet,
    iconBg: "#fff",
    date: "Aug 2016 - Jul 2022",
    points: [
      "Reduced lesson scheduling conflicts by up 50% by implementing a city-wide scheduling system made using HTML, CSS, and JavaScript.",
      "Promoted effective communication with co-teachers to build solid teamwork which allows us to achieve classroom goals and increase student pass rate by 20%",
      "Led workshops to improve cross cultural communication skills to hundreds of English teachers.",
    ],
  },
  {
    title: "Frontend Developer",
    company_name: "Skeleton Crew Studio",
    icon: scs,
    iconBg: "#fff",
    date: "Sep 2022 - Present",
    points: [
      "Developing and maintaining web applications using React.js, Three.js, TypeScript as well as other related technologies.",
      "Collaborating with cross-functional teams including designers, product managers, and other developers to create high-quality products.",
      "Implementing responsive design and ensuring cross-browser compatibility.",
      "Participating in code reviews and providing constructive feedback to other developers.",
    ],
  },
];

const testimonials = [
  {
    testimonial:
      "I thought it was impossible to make a website as beautiful as our product, but Rick proved me wrong.",
    name: "Sara Lee",
    designation: "CFO",
    company: "Acme Co",
    image: "https://randomuser.me/api/portraits/women/4.jpg",
  },
  {
    testimonial:
      "I've never met a web developer who truly cares about their clients' success like Rick does.",
    name: "Chris Brown",
    designation: "COO",
    company: "DEF Corp",
    image: "https://randomuser.me/api/portraits/men/5.jpg",
  },
  {
    testimonial:
      "After Rick optimized our website, our traffic increased by 50%. We can't thank them enough!",
    name: "Lisa Wang",
    designation: "CTO",
    company: "456 Enterprises",
    image: "https://randomuser.me/api/portraits/women/6.jpg",
  },
];

const projects = [
  {
    name: "CarHub",
    description:
      "Web-based platform that allows users to search for cars using advanced search functionality and custom filtering. It makes use of server side rendering for improved loading better SEO.",
    tags: [
      {
        name: "react",
        color: "blue-text-gradient",
      },
      {
        name: "nextjs",
        color: "green-text-gradient",
      },
      {
        name: "tailwind",
        color: "pink-text-gradient",
      },
      {
        name: "restapi",
        color: "orange-text-gradient",
      },
    ],
    image: carrent,
    source_code_link: "https://github.com/",
  },
  {
    name: "Job IT",
    description:
      "Web application that enables users to search for job openings, view estimated salary ranges for positions, and locate available jobs based on their current location.",
    tags: [
      {
        name: "react",
        color: "blue-text-gradient",
      },
      {
        name: "restapi",
        color: "green-text-gradient",
      },
      {
        name: "scss",
        color: "pink-text-gradient",
      },
    ],
    image: jobit,
    source_code_link: "https://github.com/",
  },
  {
    name: "Space Tourism",
    description:
      "A responsive website built with accessibility concerns in mind from a Figma design. I also implemented a design system to understand shared design language and guidelines that empower teams to collaborate cohesively.",
    tags: [
      {
        name: "html5",
        color: "blue-text-gradient",
      },
      {
        name: "ccs3",
        color: "green-text-gradient",
      },
      {
        name: "javascript",
        color: "pink-text-gradient",
      },
    ],
    image: spacetourism,
    source_code_link: "https://github.com/",
  },
];

export { services, technologies, experiences, testimonials, projects };
