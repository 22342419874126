import { lazy } from "react";
import { Canvas } from "@react-three/offscreen";

const Scene = lazy(() => import("./scenes/EarthScene"));
const worker = new Worker(
  new URL("./workers/earthworker.jsx", import.meta.url),
  {
    type: "module",
  }
);

const EarthCanvas = () => {
  return (
    <Canvas
      shadows
      frameloop="demand"
      dpr={[1, 2]}
      gl={{ preserveDrawingBuffer: true }}
      camera={{
        fov: 45,
        near: 0.1,
        far: 200,
        position: [-4, 3, 6],
      }}
      worker={worker}
      fallback={<Scene />}
    />
  );
};
export default EarthCanvas;
