import { lazy } from "react";
import { Canvas } from "@react-three/offscreen";

const Scene = lazy(() => import("./scenes/ComputersScene"));
const worker = new Worker(new URL("./workers/worker.jsx", import.meta.url), {
  type: "module",
});

const ComputersCanvas = () => {
  return (
    <Canvas
      shadows
      dpr={[1, 2]}
      camera={{ position: [20, 3, 5], fov: 25 }}
      gl={{ preserveDrawingBuffer: true }}
      worker={worker}
      fallback={<Scene />}
    />
  );
};

export default ComputersCanvas;
