import { lazy } from "react";
import { Canvas } from "@react-three/offscreen";

const Scene = lazy(() => import("./scenes/BallsScene"));
const worker = new Worker(
  new URL("./workers/ballworker.jsx", import.meta.url),
  {
    type: "module",
  }
);

const BallCanvas = () => {
  return (
    <Canvas
      camera={{ position: [0, 0, 15], near: 5, far: 40 }}
      worker={worker}
      fallback={<Scene />}
    />
  );
};

export default BallCanvas;
