import { lazy } from "react";
import { Canvas } from "@react-three/offscreen";

const Scene = lazy(() => import("./scenes/StarsScene"));
const worker = new Worker(
  new URL("./workers/starsworker.jsx", import.meta.url),
  {
    type: "module",
  }
);

const StarsCanvas = () => {
  return (
    <div className="w-full h-auto absolute inset-0 z-[-1]">
      <Canvas
        camera={{ position: [0, 0, 1] }}
        worker={worker}
        fallback={<Scene />}
      />
    </div>
  );
};

export default StarsCanvas;
